@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/******** optimized react select ********/
.custom-option {
  transition: background 60ms;
}
.custom-option:hover {
  transition-delay: 60ms;
}
.react-virtualized-list-wrapper li {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}
/******** custom scrollbar for long tooltip content ********/
/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  border-radius: 6px;
}
::-webkit-scrollbar-track {
  background-color: #f7fafc;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #a0aec0 #f7fafc;
}
* {
  scrollbar-arrow-color: #a0aec0;
}
